import { default as BaseDialog } from 'components/CustomBaseDialog';
import { PrimaryButton, TextButton } from '@etiquette-ui/buttons';
import { Input } from '@etiquette-ui/inputs-dropdowns';
import useI18n from 'i18n/useI18n';
import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

const Buttons = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    button {
        padding: 15px 0;
        height: unset;
    }
`;

const CreateTagDialog = ({ open, onSubmit, onClose }) => {
    const { translate } = useI18n();
    const [nameEn, setNameEn] = useState('');
    const [nameEs, setNameEs] = useState('');

    const submit = (e) => {
        e.preventDefault();

        onSubmit({ translations: { es: { title: nameEs }, en: { title: nameEn } } });
    };

    return (
        <BaseDialog open={open} onClose={onClose} isResponsive={false} title={translate('Create New Tag')}>
            <form onSubmit={submit}>
                <Input
                    labelProps={{ label: translate('Name (EN)') }}
                    iconProps={{ icon: '' }}
                    inputProps={{
                        value: nameEn,
                        onChange: (e) => setNameEn(e.target.value),
                        required: true,
                    }}
                />
                <div style={{ height: '30px' }} />
                <Input
                    labelProps={{ label: translate('Name (ES)') }}
                    iconProps={{ icon: '' }}
                    inputProps={{
                        value: nameEs,
                        onChange: (e) => setNameEs(e.target.value),
                        required: true,
                    }}
                />
                <div style={{ height: '30px' }} />
                <Buttons>
                    <TextButton onClick={onClose} type="button">
                        {translate('Cancel')}
                    </TextButton>
                    <PrimaryButton>{translate('Save')}</PrimaryButton>
                </Buttons>
            </form>
        </BaseDialog>
    );
};

export default CreateTagDialog;
