import React from 'react';
import { PSmall, Subtitle } from '@etiquette-ui/typography';
import FormSpacer from 'components/Form/FormSpacer';
import ImageUpload from 'components/ImageUpload';
import { IMAGE_SHAPES } from 'components/ImageUpload/imageShapes';
import FormSection from 'containers/Form/FormSection';
import ImageSectionContainer from 'pages/MenuItems/containers/ImageSectionContainer';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';
import ITEM_KEYS from '../keys';

const Image = styled(ImageUpload)`
    width: 250px;
`;

const Sponsor = ({ formData, handleItemInfo }) => {
  const { translate } = useI18n();

  return (
    <FormSection>
      <Subtitle>{translate('Sponsor')}</Subtitle>
      <FormSpacer />
      <ImageSectionContainer>
        <Image
          text={translate('Add Logo')}
          altText={translate('Edit Logo')}
          src={formData[ITEM_KEYS.LOGO]}
          onChange={(value) => handleItemInfo(ITEM_KEYS.LOGO, value)}
          shape={IMAGE_SHAPES.ROUNDED_RECTANGLE}
        />
        <div style={{width: '20px'}}></div>
        <PSmall>{translate('images_disclaimer')}</PSmall>
      </ImageSectionContainer>
    </FormSection>
  );
};

export default Sponsor;
