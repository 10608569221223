import {default as BaseDialog} from './CustomBaseDialog';
import { PrimaryButton, SecondaryButton } from '@etiquette-ui/buttons';
import useI18n from 'i18n/useI18n';
import React, { useEffect, useRef, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
import styled from 'styled-components';
import heic2any from 'heic2any';
import UTIF from 'utif';
import { Spinner } from '@etiquette-ui/misc';
import { PRIMARY } from '@etiquette-ui/colors';

const maxSizePx = 500
const maxSizeKb = 200 * 1024

const ButtonsContainer = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    gap: 15px;
    grid-template-columns: 48% 48%;
`;

const CustomBaseDialog = styled(BaseDialog)`
    min-height: fit-content;
`;

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}

function getInitialCrop(width, height) {
    return centerAspectCrop(width, height, 1);
}

async function convertHeicToJpg(heicBlob) {
    const output = await heic2any({
        blob: heicBlob,
        toType: "image/jpeg",
    });
    return output;
}

function convertTifToJpg(tifArrayBuffer) {
    return new Promise((resolve, reject) => {
        const ifds = UTIF.decode(tifArrayBuffer);
        if (ifds.length === 0) {
            return reject(new Error('No IFD entries found.'));
        }

        const firstIfd = ifds[0];
        const width = firstIfd['t256'] ? firstIfd['t256'][0] : 0;
        const height = firstIfd['t257'] ? firstIfd['t257'][0] : 0;

        if (width === 0 || height === 0) {
            return reject(new Error('Invalid dimensions.'));
        }

        UTIF.decodeImage(tifArrayBuffer, firstIfd);
        const rgba = UTIF.toRGBA8(firstIfd);

        const imgData = new ImageData(new Uint8ClampedArray(rgba), width, height);

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.putImageData(imgData, 0, 0);

        canvas.toBlob(resolve, 'image/jpeg');
    });
}



function getNewImage(image, completedCrop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;
    const ctx = canvas.getContext('2d');
    const crop = completedCrop;

    const croppedWidth = crop.width * scaleX * pixelRatio;
    const croppedHeight = crop.height * scaleY * pixelRatio;

    let outputWidth, outputHeight;
    if (croppedWidth > maxSizePx || croppedHeight > maxSizePx) {
        if (croppedWidth > croppedHeight) {
            outputWidth = maxSizePx;
            outputHeight = (maxSizePx / croppedWidth) * croppedHeight;
        } else {
            outputHeight = maxSizePx;
            outputWidth = (maxSizePx / croppedHeight) * croppedWidth;
        }
    } else {
        outputWidth = croppedWidth;
        outputHeight = croppedHeight;
    }

    canvas.width = outputWidth;
    canvas.height = outputHeight;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        outputWidth / pixelRatio,
        outputHeight / pixelRatio
    );

    return new Promise((resolve, reject) => {
        let quality = 1;
        const checkSizeAndResolve = (blob) => {
            if (blob.size <= maxSizeKb) {
                blob.name = 'newImage.jpeg';
                const fileURL = window.URL.createObjectURL(blob);
                resolve(fileURL);
            } else if (quality > 0.1) {
                quality -= 0.05;
                canvas.toBlob(checkSizeAndResolve, 'image/jpeg', quality);
            } else {
                reject(`Unable to compress image below ${maxSizeKb / 1024} kB`);
            }
        };

        canvas.toBlob(checkSizeAndResolve, 'image/jpeg', quality);
    });
}

const ImageCropDialog = ({ open, onSubmit, onClose, src, fileType, isSquareAspect = true}) => {
    const [crop, setCrop] = useState(centerAspectCrop(100, 100, 1));
    const [completedCrop, setCompletedCrop] = useState(getInitialCrop(100, 100));
    const [loading, setLoading] = useState(false);
    const [convertedSrc, setConvertedSrc] = useState(null);
    const imgRef = useRef(null);
    const { translate } = useI18n()

    useEffect(() => {
        (async function () {
            if (fileType === 'image/heic') {
                setLoading(true)
                const response = await fetch(src);
                const heicBlob = await response.blob();
                const jpgBlob = await convertHeicToJpg(heicBlob);
                const objectURL = URL.createObjectURL(jpgBlob);
                setConvertedSrc(objectURL);
                setLoading(false)
            }

            if (fileType === 'image/tiff') {
                setLoading(true)
                const response = await fetch(src);
                const tifArrayBuffer = await response.arrayBuffer();
                const jpgBlob = await convertTifToJpg(tifArrayBuffer);
                const objectURL = URL.createObjectURL(jpgBlob);
                setConvertedSrc(objectURL);
                setLoading(false)
            }
        })()
    }, [])

    const onCropChange = (_, percentCrop) => {
        setCrop(percentCrop);
    };

    function onImageLoad(e) {
        const { width, height } = e.currentTarget;
        const initialCrop = getInitialCrop(width, height);

        setCrop(initialCrop);
        setCompletedCrop({
            ...initialCrop,
            width,
            height
        });
    }

    const onComplete = (c) => {
        setCompletedCrop(c);
    };
    const confirmCrop = async () => {
        setLoading(true);
        try {
            const img = await getNewImage(imgRef.current, completedCrop);
            onSubmit(img);
        } catch (error) {
            alert(error)
        }
    };

    return (
        <CustomBaseDialog open={open} onClose={onClose} isResponsive={false}>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner
                        style={{
                            '--color': PRIMARY,
                            '--bg': 'white',
                            marginRight: '10px',
                        }}
                    />
                </div>
            ) :
                <>
                    <ReactCrop crop={crop} aspect={!!isSquareAspect ? 1 : undefined} onComplete={onComplete} onChange={onCropChange}>
                        <img style={{maxWidth:'480px'}} ref={imgRef} alt="Crop me" src={convertedSrc || src} onLoad={onImageLoad} />
                    </ReactCrop>
                    <ButtonsContainer>
                        <PrimaryButton type={'submit'} disabled={!completedCrop || loading} onClick={confirmCrop}>
                            {translate("Continue")}
                        </PrimaryButton>
                        <SecondaryButton onClick={onClose}>
                            {translate("Cancel")}
                        </SecondaryButton>
                    </ButtonsContainer>                    
                </>
            }

        </CustomBaseDialog>
    );
};
export default ImageCropDialog;