import styled from 'styled-components';
import { SecondaryButton } from '@etiquette-ui/buttons';
import { IMAGE_SHAPES } from './imageShapes';
import { WRONG_DARK, TEXT_PLACEHOLDER } from '@etiquette-ui/colors';

const Container = styled.div`
  width: 100%;
  min-height: 56px;
  display: flex;
`;

const Content = styled.div`
  height: 100%;
  position: relative;
  flex: 1;
`;

const ImageContainer = styled.div`
  display: flex;
  position: relative;
`;

const Caption = styled.p`
  top: 100%;
  left: 5px;
  width: 100%;
  margin: 0px;
  font-size: 12px;
  font-weight: 500;
  min-width: 300px;
  padding-top: 3px;
  position: absolute;
  pointer-events: none;
  color: ${TEXT_PLACEHOLDER};
`;

const Error = styled.li`
  font-size: 12px;
  font-weight: 500;
  list-style: circle;
  margin-top: 2px;
  color: ${WRONG_DARK};

  &::marker {
    margin-right: -3px;
  }
`;

const SquareStyles = `
  max-width: 200px;
  max-height: 200px;
  width: 200px;
`;

const PortraitStyles = `
  max-width: 200px;
  height: 300px;
  max-height: 300px;
  width: auto;
`;

const RoundedRectangleStyles = `
  max-width: 200px;
  max-height: 120px;
  width: 200px;
  border-radius: 8px;
`;

const CircleStyles = `
  max-width: 120px;
  max-height: 120px;
  width: 120px;
  border-radius: 60px;
`;

const Image = styled.img`
  object-fit: cover;
  overflow: hidden;
  ${({ cutShape }) => {
    switch (cutShape) {
      case IMAGE_SHAPES.CIRCLE:
        return CircleStyles;
      case IMAGE_SHAPES.SQUARE:
        return SquareStyles;
      case IMAGE_SHAPES.PORTRAIT:
        return PortraitStyles;
      case IMAGE_SHAPES.ROUNDED_RECTANGLE:
      default:
        return RoundedRectangleStyles;
    }
  }}
`;

const Button = styled(SecondaryButton)`
  width: 175px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: unset;
    margin: unset;
    height: 50px;
    width: 100%;
    font-weight: 600;
  }
  &.alt {
    position: absolute;
    left: 180px;
    bottom: 0;
  }

  @media( max-width: 768px ){
    &.alt {
      left: auto;
      right: 0px;
    }
  
  }
`;

const RemoveImg = styled.div`
  position: absolute;
  width: 24px;
  ${({ cutShape }) => {
    switch (cutShape) {
      case IMAGE_SHAPES.CIRCLE:
        return 'left: 95px; top: 0px;';
      case IMAGE_SHAPES.SQUARE:
      case IMAGE_SHAPES.ROUNDED_RECTANGLE:
        case IMAGE_SHAPES.PORTRAIT:
      default:
        return 'left: 188px; top: -12px;';
    }
  }}
  cursor: pointer;
`;

export {
  Container,
  Content,
  ImageContainer,
  Caption,
  Error,
  RoundedRectangleStyles,
  Image,
  Button,
  RemoveImg
}