
import React from 'react';
import useI18n from 'i18n/useI18n';
import { useRouteMatch } from 'react-router';
import { SecondaryButton } from '@etiquette-ui/buttons';
import { default as BaseDialog } from 'components/CustomBaseDialog';
import { Title, Container, Description, List, ButtonsContainer, PrimaryButton } from './styles';

const Dialog = ({open, onSubmit, onClose, setIsAlertOpened, list}) => {
  const { translateObject } = useI18n();
  const { path } = useRouteMatch();
  const translations = {
    en: {
      title: '"86 No Stock" notification',
      description: `Products below shouldn't be as "86 No stock". Verify the stock to modify the status or change the date to re-stock them.`,
      later_button: 'Remind Me Later',
      ok_button: 'View Details',
      since: 'Since'
    },
    es: {
      title: 'Notificación "86 Sin Stock"',
      description: 'Los productos a continuación no deberían estar en "86 sin stock". Revisa sus existencias y modifica el estado o establece una nueva fecha de abastecimiento.',
      later_button: 'Recordar Más Tarde',
      ok_button: 'Ver Más',
      since: 'Desde'
    }
  }
  const handleClick = () => {
    setIsAlertOpened();
    onClose();
  }
  return(
    <BaseDialog open={open} onClose={onClose} isResponsive={true}>
      <Container>
        <Title>
          {translateObject(translations).title}
        </Title>
        <Description>
          {translateObject(translations).description}
        </Description>
        <List>
          {list.map( item => (<li key={item.id}>{translateObject(item.translations).title}. <span>{translateObject(translations).since} {new Date(item.when).toLocaleDateString()}</span></li>))}
        </List>
        <ButtonsContainer>
          <SecondaryButton onClick={handleClick}>
            {translateObject(translations).later_button}
          </SecondaryButton>
          <PrimaryButton to={`${path}menu/items/management-86`} onClick={handleClick}>
            {translateObject(translations).ok_button}
          </PrimaryButton>
        </ButtonsContainer>
      </Container>
    </BaseDialog>
  );
}
export default Dialog;