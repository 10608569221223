import React from 'react';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';
import { formatMoney } from 'utils/text';
import { INPUTS } from '@etiquette-ui/colors';
import { default as BaseDialog } from 'components/CustomBaseDialog';
import { PrimaryButton, SecondaryButton } from '@etiquette-ui/buttons';

const Title = styled.h1`
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 24px;
  text-align: center;
`;
const Text = styled.p`
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 14px;
`;
const TableContainer = styled.div`
  Table {
    tr {
      th, td {
        padding: 15px;
      }
    }
    tr:nth-child(2n){
      background-color: ${INPUTS};
    }
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: wrap;

  button {
    font-weight: 600;
    width: 160px;
  }
`;

const ConfirmNewPrices = ({open, onClose, onSubmit, data, onClean}) => {
  const { translate, translateObject, selectedLang } = useI18n();

  const onCancel = () => {
    onClean();
    onClose();
  }

  const translations = {
    en: {
      title: 'Prices Confirmation',
      body: 'Are you sure you want to change the prices?',
      okButton: 'Yes, Change Prices'
    },
    es:{
      title: 'Confirmar nuevos precios',
      body: '¿Deseas continuar con la actualización de los siguientes precios?',
      okButton: 'Sí, guardar.'
    }
  }

  const theader = {
    en:{
      name: 'Item name',
      oldPrice: 'Old Price',
      newPrice: 'New Price',
    },
    es:{
      name: 'Nombre',
      oldPrice: 'Precio actual',
      newPrice: 'Nuevo Precio',
    }
  }

  const getTitle = (product, id) => {
    if (product.variants.length < 2) {
      return translateObject(product.translations).title;
    }
    
    const variant = product.variants.find(variant => +variant.id === +id);
    return `${translateObject(product.translations).title} (${selectedLang === 1 ? variant.translations.en : variant.translations.es})`;
  }

  return (
    <BaseDialog open={open} onClose={onClose} dismissible={false} isResponsive={true} header={''}>
      <Title>
        {translateObject(translations).title}
      </Title>
      <Text>
        {translateObject(translations).body}
      </Text>
      <TableContainer>
        <table>
          <tr>
            <th>{translateObject(theader).name}</th>
            <th>{translateObject(theader).oldPrice}</th>
            <th>{translateObject(theader).newPrice}</th>
          </tr>
          {Object.values(data).filter(({price, variant})=>price !== variant.price).map( ({product, variant, price})=>(
            <tr key={variant.id}>
              <td>{getTitle(product, variant.id)}</td>
              <td>{formatMoney(variant.price)}</td>
              <td>{formatMoney(price)}</td>
            </tr>
          ))}
        </table>
      </TableContainer>
      <ButtonsContainer>
        <SecondaryButton onClick={onCancel}>{translate('Cancel')}</SecondaryButton>
        <PrimaryButton onClick={onSubmit}>{translateObject(translations).okButton}</PrimaryButton>
      </ButtonsContainer>
    </BaseDialog>
  )
}
export default ConfirmNewPrices;
