import { SecondaryButton } from '@etiquette-ui/buttons';
import { P } from '@etiquette-ui/typography';
import React from 'react';
import { Content, ImageUploadFrame } from './styles';
import ImageUploading from 'react-images-uploading';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';
import { PRIMARY_LIGHT, PRIMARY_DARK } from '@etiquette-ui/colors';

const FileButton = styled.span`
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    padding: 10px 15px;
    color: ${PRIMARY_LIGHT};
    transition-duration: 3ms;
    transition-property: color, border, font-weight;
    border: dashed 1px ${PRIMARY_LIGHT};

    &:hover {
        cursor: pointer;
        color: ${PRIMARY_DARK};
        border: dashed 1px ${PRIMARY_DARK};
    }
`;

const ImageUpload = ({ onChange }) => {

    const { translate } = useI18n();

    return (<Content>
        <ImageUploading
            onChange={onChange}
            acceptType={["jpg", "jpeg", "png", "tiff", "tif", "webpg", "heic"]}
            allowNonImageType={true}
        >
            {({ imageList, onImageUpload, isDragging, dragProps }) => (
                <ImageUploadFrame dragging={isDragging} {...dragProps}>
                    <P style={{marginBottom: '10px'}}>
                        {isDragging ? translate('Drop file here') : translate('Drop files to upload or')}
                    </P>
                    {!isDragging && <FileButton onClick={onImageUpload}>
                        {translate('Select File')}
                    </FileButton>}
                </ImageUploadFrame>
            )}
        </ImageUploading>
    </Content>);
}

export default ImageUpload;
