import React, { useState } from 'react';
import { DialogContainer, Header, PickersSection, SearchSection, Select, ActionsContainer, Cancel, Accept, Add } from './styles';
import { PRIMARY } from '@etiquette-ui/colors';
import useI18n from 'i18n/useI18n';
import useStep from 'hooks/useStep';
import ImageGrid from './ImageGrid';
import { Input } from '@etiquette-ui/inputs-dropdowns';
import ImageUpload from './ImageUpload';
import { ALERT_TYPES, AlertServiceProvider, useAlert } from '@etiquette-ui/alert-dialog';
import ImageCropDialog from 'components/ImageCropDialog';

const imageOptions = [
    { value: 0, label: 'Category' },
    { value: 1, label: 'Items' },
];

const Dialog = ({ open, onSubmit, onClose, defaultOption = 0 }) => {
    const { translate } = useI18n();
    const [selectedOption, setSelectedOption] = useState(imageOptions[defaultOption]);
    const [step, , , changeStep] = useStep();
    const [searchField, setSearchField] = useState('');
    const [selectedImageId, setSelectedImageId] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);
    const alert = useAlert()

    const handleOptions = (opt) => {
        setSelectedOption(opt);
        changeStep(0);
    };

    const onChange = (imageList) => {
        if (imageList.length > 0) {
            setUploadedImage(imageList[0]);
            setSelectedImageId(-1);
            setSelectedImage(imageList[0]);
            changeStep(0);
        }
    };

    const cropImage = async (imageList) => {
        const url = await alert({
            type: ALERT_TYPES.CUSTOM,
            config: {
                body: ImageCropDialog,
                props: {
                    src: imageList[0].dataURL,
                    fileType: imageList[0].file.type || `image/${imageList[0].file.name.split('.').pop()}`
                },
            },
        })

        const rawFile = await fetch(url)
        const blobFile = await rawFile.blob()
        const file = new File([blobFile], 'newImage.jpeg', { type: 'image/jpeg' })
        const resultImage = {
            dataURL: url,
            file
        }
        setUploadedImage(resultImage);
        setSelectedImageId(-1);
        setSelectedImage(resultImage);
        changeStep(0);
    }

    const onSave = () => {
        if (selectedImage === null && uploadedImage === null) return;
        onSubmit(selectedImage);
    };

    return (
        <DialogContainer 
            open={open} 
            onClose={onClose} 
            isResponsive={true}
            modalProps={{
                style: {
                    content: {
                        height: '80%',
                        width: '90%'
                    }
                }
            }}
        >
            <Header>
                <PickersSection>
                    <Select
                        selectProps={{
                            value: selectedOption,
                            onChange: handleOptions,
                            options: imageOptions.map((opt) => ({ ...opt, label: translate(opt.label) })),
                            placeholder: translate('Pick one...'),
                        }}
                        labelProps={{
                            label: translate('Images from'),
                        }}
                        containerProps={{
                            styles: {
                                maxWidth: '300px'
                            }
                        }}
                    />
                    <Add onClick={() => changeStep(1)} width="150px" backgroundColor={PRIMARY}>
                        {translate('Add New')}
                    </Add>
                </PickersSection>
                <SearchSection>
                    <Input
                        inputProps={{ value: searchField, onChange: (e) => setSearchField(e.target.value) }}
                        iconProps={{ icon: 'search' }}
                        labelProps={{ label: translate('Search') }}
                        style={{
                            maxWidth: '350px'
                        }}
                    />
                </SearchSection>
            </Header>
            {step === 0 && (
                <ImageGrid
                    selectedOption={selectedOption}
                    searchField={searchField}
                    uploadedImage={uploadedImage}
                    setSelectedImage={setSelectedImage}
                    selectedId={selectedImageId}
                    setSelectedId={setSelectedImageId}
                />
            )}
            {step === 1 && (<>
                {/* <div style={{flex: '1'}}> */}
                    <ImageUpload onChange={cropImage} />
                {/* </div> */}
            </>)}
            <ActionsContainer>
                <Cancel onClick={() => onClose()} margin={'5px 5px 5px auto'}>
                    {translate('Cancel')}
                </Cancel>
                <Accept onClick={onSave}>{translate('Save')}</Accept>
            </ActionsContainer>
        </DialogContainer>
    );
};

const ImagePickerDialog = (props) =>
    <AlertServiceProvider>
        <Dialog {...props} />
    </AlertServiceProvider>

export default ImagePickerDialog;
