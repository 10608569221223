import React, { useState } from 'react';
import { default as BaseDialog } from 'components/CustomBaseDialog';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';
import PrimaryButton, { TextButton } from '@etiquette-ui/buttons';
import { dateFormat, DATE_FORMAT } from 'utils/date';
import Reason from './Reason';
import Calendar from './Calendar';
import History from './History';

const Buttons = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    button {
        padding: 15px 0;
        height: unset;
    }
`;

const Dialog = styled(BaseDialog)`
    & > div {
        max-height: 95% !important;
        & > div {
          overflow: scroll;
        }
    }
`;

const AddComment = ({ open, onSubmit, onClose, item }) => {
    const { translate, translateObject } = useI18n();
    const [reason, setReason] = useState('');
    const [date, setDate] = useState(new Date());
    const disabled = !reason || !date;

    const submit = (e) => {
        e.preventDefault();
        if (disabled) return;

        onSubmit({ date: dateFormat(date, DATE_FORMAT), reason });
    };

    return (
        <Dialog open={open} onClose={onClose} isResponsive={false} title={translateObject(item.translations).title}>
            <form style={{display: 'flex', flexDirection: 'column'}} onSubmit={submit}>
                <div style={{ flex: 1, overflow: 'scroll' }}>
                    <div style={{ height: '10px' }} />
                    <History item={item} />
                    <div style={{ height: '30px' }} />
                    <Reason reason={reason} setReason={setReason} />
                    <div style={{ height: '30px' }} />
                    <Calendar date={date} setDate={setDate} />
                </div>
                <div style={{ height: '30px' }} />
                <Buttons>
                    <TextButton onClick={onClose}>{translate('Cancel')}</TextButton>
                    <PrimaryButton disabled={disabled}>{translate('Add Comment')}</PrimaryButton>
                </Buttons>
            </form>
        </Dialog>
    );
};

export default AddComment;
