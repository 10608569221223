import React, {useState} from 'react';
import useI18n from 'i18n/useI18n';
import ImageUploading from 'react-images-uploading';
import { IMAGES_URL } from 'resources/constants/urls';
import { IMAGE_SHAPES } from './imageShapes';
import { ImageIcon, RemoveIcon } from '@etiquette-ui/icons';
import { Container, Content, ImageContainer, Caption, Error, Image, Button, RemoveImg } from './styles';

const imgResolution = {
  width: 150,
  height: 150
}

const translations = {
  es: {
    caption: 'Elige una imagen mayor que',
    error: 'debe ser mayor que',
    image: 'Imagen'
  },
  en: {
    caption: 'Image should be bigger than',
    error: 'must be bigger than',
    image: 'Image',
  }
}

const ImageUpload = ({ 
  className, 
  text, 
  altText, 
  error='', 
  src, 
  onChange,
  onDelete,
  shape = IMAGE_SHAPES.SQUARE, 
  onClick = null, 
  extraTypes = '',
  resolutionType = 'more',
  resolutionWidth = imgResolution.width,
  resolutionHeight = imgResolution.height
}) => {

  const [imgErrors, setImgErrors] = useState(!!error ? [error] : []);
  const { translateObject } = useI18n();

  const types = [
    ...["jpg", "jpeg", "png", "tiff", "webpg", "heic"],
    ...extraTypes.split(',')
  ];

  const handleChange = (imageList) => {
    if (imageList.length > 0) {
      onChange(imageList[0]);
    }
    setImgErrors(!!error ? [error] : []);
  };

  const getImgSrc = (src) => {
    if (typeof src === 'string' || src instanceof String) return `${IMAGES_URL}${src}`;
    if (typeof src === 'object') return src.dataURL;
    return '';
  };

  const onError = (errors, files) => {
    if(!!errors && !!errors.resolution) {
      let newImgErrors = !!error ? [error] : [];
      for(const file of files){
        newImgErrors = [
          ...newImgErrors,
          `${translateObject(translations).image}: ${file.file.name} ${translateObject(translations).error} ${imgResolution.width}x${imgResolution.height} pxs`
        ]
      }
      setImgErrors(newImgErrors);
    }
  }

  return (
    <Container className={className}>
      <Content>
        <ImageUploading 
          onChange={handleChange} 
          onError={onError}
          allowNonImageType={true} 
          acceptType={types}
          resolutionType={resolutionType}
          resolutionWidth={resolutionWidth}
          resolutionHeight={resolutionHeight}
        >
          {({ onImageUpload }) => (
            <ImageContainer>
              {src && <Image src={getImgSrc(src)} alt={'new group profile'} cutShape={shape} />}
              <Button type="button" onClick={onClick ? onClick : onImageUpload} className={src ? 'alt' : ''}>
                <ImageIcon styles={{ svg: { width: '25px', heigth: '25px', marginRight: '8px' } }} />
                {src ? altText : text}
              </Button>
              <Caption>{`${translateObject(translations).caption} ${imgResolution.width}x${imgResolution.height} pxs`}</Caption>
              {src && (
                <RemoveImg onClick={onDelete} cutShape={shape}>
                  <RemoveIcon />
                </RemoveImg>
              )}
              {imgErrors.length > 0 && (
                <ul>
                  {imgErrors.map( (error) => (
                    <Error>{error}</Error>
                  ))}
                </ul>
              )}
            </ImageContainer>            
          )}
        </ImageUploading>
      </Content>
    </Container>
  );
};
export default ImageUpload;