import React, { useState } from 'react';
import { PRIMARY } from '@etiquette-ui/colors';
import { PasswordIcon } from '@etiquette-ui/icons';
import { Input } from '@etiquette-ui/inputs-dropdowns';
import { Checkbox, SwitchButton } from '@etiquette-ui/selectors';
import { P, Subtitle } from '@etiquette-ui/typography';
import { CustomSecondaryButton } from 'components/Buttons';
import FormSpacer from 'components/Form/FormSpacer';
import ImageUpload from 'components/ImageUpload';
import FormSection from 'containers/Form/FormSection';
import GridContainer from 'containers/Form/GridContainer';
import useI18n from 'i18n/useI18n';
import { ITEM_STATUS } from 'utils/status';
import ITEM_KEYS, { CONFIG_ITEM_KEYS } from '../keys';
import { ACTIONS } from '../Reducer';
import { TextInactive } from 'components/Texts';
import styled from 'styled-components';
import ImageCropDialog from 'components/ImageCropDialog';
import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import WYSIWYG from 'components/WYSIWYG';

const EnableDescriptionSection = styled.div`
  display: flex;

  & .checkColumn {
    margin-right: 15px;
    display: flex;
    align-items: center;

    div {
      position: relative;
      div {
        position: absolute;
        top: 0px;
        left: 3px;
      }
      p {
        position: relative;
        width: 100%;
        padding-left: 1.5rem;
        font-size: 14px;
      }
    }
  }

  @media( max-width: 768px ){
    & .checkColumn {
      flex: 0 0 35%;
      max-width: 35%;
      margin-right: 0px;

      div div { top: 3px; }
    }
  
    & .blockButtonColumn {
      flex: 0 0 65%;
      max-width: 65%;
      padding: 0px 0px 0px 10px;
    }
  }
`;

const Details = ({ formData, dispatch, handleItemInfo }) => {
  const { translate } = useI18n();
  const [requiredEnabled, setRequiredEnabled] = useState(false);
  const alert = useAlert()

  const uploadImage = async (url) => {
    const rawFile = await fetch(url)
    const blobFile = await rawFile.blob()
    const file = new File([blobFile], 'newImage.jpeg', { type: 'image/jpeg' })
    handleItemInfo(ITEM_KEYS.IMAGE, {
      dataURL: url,
      file
    })
  };


  return (
    <FormSection>
      <Subtitle>{translate('Item Details')}</Subtitle>
      <FormSpacer />
      <ImageUpload
        text={translate('Add Photo')}
        altText={translate('Edit Photo')}
        src={formData[ITEM_KEYS.IMAGE]}
        onDelete={()=>{
          handleItemInfo(ITEM_KEYS.IMAGE, '');
        }}
        onChange={(value) => {
          return alert({
            type: ALERT_TYPES.CUSTOM,
            config: {
              body: ImageCropDialog,
              props: {
                src: value.dataURL,
                fileType: value.file.type || `image/${value.file.name.split('.').pop()}`
              },
            },
          }).then((croppedImage) => {
            uploadImage(croppedImage)
          })
        }}
      />
      <FormSpacer />
      <GridContainer>
        <Input
          labelProps={{ label: translate('Item Name (English)') }}
          iconProps={{ icon: 'title' }}
          inputProps={{
            ...{
              value: formData[ITEM_KEYS.TRANSLATIONS].en.title,
              onChange: (e) => dispatch(ACTIONS.TRANSLATIONS, { lang: 'en', text: 'title', value: e.target.value }),
              required: true,
            },
          }}
        />
        <Input
          labelProps={{ label: translate('Item Name (Spanish)') }}
          iconProps={{ icon: 'title' }}
          inputProps={{
            value: formData[ITEM_KEYS.TRANSLATIONS].es.title,
            onChange: (e) => dispatch(ACTIONS.TRANSLATIONS, { lang: 'es', text: 'title', value: e.target.value }),
            required: true,
          }}
        />
      </GridContainer>
      <FormSpacer />
      <EnableDescriptionSection>
        <div className="checkColumn">
          <Checkbox
            checked={formData[ITEM_KEYS.CONFIG][CONFIG_ITEM_KEYS.DESCRIPTION_REQUIRED]}
            disabled={!requiredEnabled}
            onClick={requiredEnabled ? () => dispatch(ACTIONS.CONFIG, { key: [CONFIG_ITEM_KEYS.DESCRIPTION_REQUIRED], value: !formData[ITEM_KEYS.CONFIG][CONFIG_ITEM_KEYS.DESCRIPTION_REQUIRED] }) : () => { }}
          >
            <TextInactive disabled={!requiredEnabled}>
              {translate(formData[ITEM_KEYS.CONFIG][CONFIG_ITEM_KEYS.DESCRIPTION_REQUIRED] ? 'Description required' : 'Description optional')}
            </TextInactive>
          </Checkbox>
        </div>
        <div className="blockButtonColumn">
          <CustomSecondaryButton onClick={() => setRequiredEnabled(!requiredEnabled)} type="button">
            <PasswordIcon
              styles={{
                svg: { width: '20px', height: '20px', marginRight: '8px' },
                line: {
                  fill: 'none',
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                  strokeWidth: '1.5px',
                  stroke: PRIMARY,
                },
              }}
            />
            {translate(requiredEnabled ? 'Click here to lock' : 'Click here to edit')}
          </CustomSecondaryButton>
        </div>
      </EnableDescriptionSection>
      <FormSpacer />
      <GridContainer>
        <WYSIWYG
          onChange={(value) => dispatch(ACTIONS.TRANSLATIONS, { lang: 'en', text: 'description', value })}
          value={formData[ITEM_KEYS.TRANSLATIONS].en.description}
          iconProps={{ icon: 'align-left' }}
          labelProps={{ label: translate('Description (English)') }}
        />
        <WYSIWYG
          onChange={(value) => dispatch(ACTIONS.TRANSLATIONS, { lang: 'es', text: 'description', value })}
          value={formData[ITEM_KEYS.TRANSLATIONS].es.description}
          iconProps={{ icon: 'align-left' }}
          labelProps={{ label: translate('Description (Spanish)') }}
        />
      </GridContainer>
      <FormSpacer />
      <div style={{ display: 'flex' }}>
        <SwitchButton onClick={() => dispatch(ACTIONS.STATUS)} active={formData[ITEM_KEYS.STATUS] === ITEM_STATUS.ACTIVE} />
        <div style={{ width: '15px' }}></div>
        <P>
          {translate(
            formData[ITEM_KEYS.STATUS] === ITEM_STATUS.ACTIVE ? 'Item visible and active' : 'Item not visible and inactive'
          )}
        </P>
      </div>
    </FormSection>
  );
};

export default Details;
