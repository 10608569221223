import React from 'react';
import { BaseDialog } from '@etiquette-ui/alert-dialog';

const CustomBaseDialog = (props) => {
  const {modalProps = {}, ...rest} = props;
  const {style = { overlay:{}, content: {}}, ...restModalProps} = modalProps;
  return (
    <BaseDialog 
      {...rest}
      modalProps={{
        style: {
          overlay: {
            zIndex: '10001',
            ...style.overlay
          },
          content: style.content
        },
        ...restModalProps
      }}
    />
  )
}
export default CustomBaseDialog;
